import Vue from 'vue'
import {
  BootstrapVue, ToastPlugin, ModalPlugin, BootstrapVueIcons,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as Sentry from '@sentry/vue'

import VueNumeric from 'vue-numeric'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import DOMPurify from 'isomorphic-dompurify'
import VueTour from 'vue-tour'
import router from './router'
import store from './store'
import App from './App.vue'
import mixins from '@/common/mixins'

import * as My2c2p from '@/assets/js/my2c2p-sandbox.1.7.3.min'
import '@/libs/sweet-alerts'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'

import ImageFallBack from '@/common/imageFallback.directive'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@validations'

import mixin from '@/common/mixin'

require('vue-tour/dist/vue-tour.css')
require('@core/assets/fonts/feather/iconfont.css')

Vue.use(VueTour)

if (process.env.VUE_APP_DEBUG_FLAG_NAME === 'production' || process.env.VUE_APP_DEBUG_FLAG_NAME === 'staging' || process.env.VUE_APP_DEBUG_FLAG_NAME === 'sandbox') {
  Sentry.init({
    Vue,
    dsn: 'https://8d4270bd7a7370d14fafee452226174f@o4505899203428352.ingest.sentry.io/4505899319623680',
    environment: `${process.env.VUE_APP_PAYMENT_GATEWAY}-${process.env.VUE_APP_DEBUG_FLAG_NAME}`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', process.env.VUE_APP_PORTAL_BASE_URL],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueMask)

Vue.directive('image-fall-back', ImageFallBack)

Vue.use(VueNumeric)
Vue.use(VueMeta)

// console.log('MODE', process.env.NODE_ENV)
// console.log('VUE_APP_TITLE', process.env.VUE_APP_TITLE)
// console.log('VUE_APP_API_URL', process.env.VUE_APP_API_URL)

Vue.mixin(mixin)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      CLIENT_IDENTIFIER: process.env.VUE_APP_CLIENT_IDENTIFIER,
      QUILL_OPTS: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            // [{ header: 1 }, { header: 2 }],
            // [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ indent: '-1' }, { indent: '+1' }],
            // [{ direction: 'rtl' }],
            // [{ size: ['small', false, 'large', 'huge'] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ font: [] }],
            // [{ color: [] }, { background: [] }],
            // [{ align: [] }],
            // ['clean'],
            ['link', 'image', 'video'],

          ],
        },
      },
      IS_SANDBOX: process.env.VUE_APP_DEBUG_FLAG_NAME === 'sandbox',
      IS_DEVELOPMENT: process.env.VUE_APP_DEBUG_FLAG_NAME === 'development',
      IS_STAGING: process.env.VUE_APP_DEBUG_FLAG_NAME === 'staging',
      IS_STAGING_LOCAL: process.env.VUE_APP_DEBUG_FLAG_NAME === 'staginglocal',
      IS_PRODUCTION: process.env.VUE_APP_DEBUG_FLAG_NAME === 'production',
      IS_PRODUCTION_LOCAL: process.env.VUE_APP_DEBUG_FLAG_NAME === 'productionlocal',

      LP_CALLBACK_URL: process.env.VUE_APP_CALLBACK_URL,
      LP_REDIRECT_URL: process.env.VUE_APP_REDIRECT_URL,

      LEANX_PERFORMANCE_METRICS_SERVER: process.env.VUE_APP_LEANX_PERFORMANCE_METRICS_URL,

      SUPPORT_EMAIL: 'support@payright.my',
      SUPPORT_PHONE_NO: '012-3332233',

      debugMode: process.env.NODE_ENV === 'development', // reverse this flag back

      myCurrency: 'MYR',
      datatableDefaultConfig: {
        selectedPerPage: 10,
      },
      defaultFavIcon: '',
      defaultCompanyLogo: '',
      defaultBannerImg: 'https://lean.sgp1.digitaloceanspaces.com/CDN-64107A_IMAGE_DEFAULT_NAME_7DFA_LEANX_lp_logoalt.png',
      defaultProductImg: 'https://lean.sgp1.digitaloceanspaces.com/CDN-5C11E7_IMAGE_DEFAULT_NAME_8DAD_LEANX_default_product_image.png',
      defaultTheme: {
        defaultBgColor: '#ccc',
      },
      defaultDateRange: {
        startDate: '01-01-1990',
        endDate: '01-01-2100',
      },
      fieldSize: 'sm',
      branding: {
        loginLogo: process.env.VUE_APP_LOGIN_LOGO_URL,
        mainLogo: process.env.VUE_APP_SITE_LOGO_URL,
        loadingLogo: process.env.VUE_APP_SITE_LOADING_LOGO_URL,
      },
      assetsLocation: {
        bankLogoDirectory: process.env.VUE_APP_BANK_LOGO_ASSETS_DIRECTORY,
        payoutBankLogoDirectory: process.env.VUE_APP_PAYOUT_BANK_LOGO_ASSETS_DIRECTORY,
      },
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 250, text: '250' },
        { value: 500, text: '500' },
      ],
      DEFAULT_TOUR_SETTINGS: [
        { routeName: 'dashboard', tour_enabled: true },
      ],
    }
  },
  computed: {
    collectionBaseUrl() {
      // if (process.env.NODE_ENV === 'development') {
      //   return '/pay/collections/'
      // }
      return process.env.VUE_APP_COLLECTIONS_BASE_URL
    },
    collectionBillsBaseUrl() {
      // if (process.env.NODE_ENV === 'development') {
      //   return '/pay/collections/'
      // }
      return process.env.VUE_APP_COLLECTIONS_BASE_URL
    },
    invoiceBaseUrl() {
      // if (process.env.NODE_ENV === 'development') {
      //   return '/pay/invoice/'
      // }
      return process.env.VUE_APP_INVOICE_BASE_URL
    },
    pendingInvoiceBaseUrl() {
      // if (process.env.NODE_ENV === 'development') {
      //   return '/pay/invoice/'
      // }
      return process.env.VUE_APP_PENDING_BILLS_BASE_URL
    },
    successInvoiceBaseUrl() {
      // if (process.env.NODE_ENV === 'development') {
      //   return '/pay/invoice/'
      // }
      return process.env.VUE_APP_SUCCESS_BILLS_BASE_URL
    },
    mini() {
      return {
        CRYPTOCURRENCY: [
          { path: `${this.assetsLocation.bankLogoDirectory}mini/TRIPPLEA_CRYPTOCURRENCY.png` },
        ],
        GLOBAL_CARD_PAYMENT: [
          { path: `${this.assetsLocation.bankLogoDirectory}mini/MASTERCARD.png` },
          { path: `${this.assetsLocation.bankLogoDirectory}mini/VISA.png` },
        ],
        WEB_PAYMENT: [
          { path: `${this.assetsLocation.bankLogoDirectory}mini/MAYBANK.png` },
          // { path: `${this.assetsLocation.bankLogoDirectory}mini/CIMBCLICKS.png` },
        ],
        DIGITAL_PAYMENT: [
          { path: `${this.assetsLocation.bankLogoDirectory}mini/BOOST.png` },
          { path: `${this.assetsLocation.bankLogoDirectory}mini/TNG.png` },
        ],
        BUY_NOW_PAY_LATER: [
          { path: `${this.assetsLocation.bankLogoDirectory}mini/ATOME.png` },
        ],
        SWITCH_PAYPAL: [
          { path: `${this.assetsLocation.bankLogoDirectory}mini/PAYPAL.png` },
        ],
      }
    },
  },
  methods: {
    processedCdnImage(cdnUrl, dimensionsParam = { width: 400, height: 400 }, gravity = 'smart') {
      const IMAGE_PROCESSING_SERVER = process.env.VUE_APP_IMAGE_SERVER_URL

      let image = cdnUrl
      if (Array.isArray(image)) {
        image = image[0]
      }
      if (typeof image === 'string') {
        image = image
      }

      const dimensionsProcessedParam = `width=${dimensionsParam.width}&height=${dimensionsParam.height}`
      return `${IMAGE_PROCESSING_SERVER}/crop?gravity=${gravity}&${dimensionsProcessedParam}&url=${image}`
    },
    purify(text) {
      return `${DOMPurify.sanitize(text)}`
    },
    handleImage(data) {
      const img = data
      const parsed = JSON.parse(img)
      // console.log(typeof parsed)
      // console.log(data)
      if (typeof parsed === 'object') {
        return parsed[0]
      }
      return img
    },
    doCopy(elem, elementName = null) {
      this.$copyText(elem).then(() => {
        let label
        if (elementName) {
          label = elementName
        } else {
          label = 'Text'
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${label} copied`,
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
})

Vue.prototype.My2c2p = My2c2p

new Vue({
  router,
  store,
  mixins: [mixins],
  render: h => h(App),
}).$mount('#app')
